'use strict';

var $ = require('jquery');

module.exports = {
    toggleSlide: function () {
        $('.js-dropdown-link').click(function () {
            $(this).find('.js-dropdown-content').slideToggle();
        });
    }
};
